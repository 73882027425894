// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-contribuer-tsx": () => import("./../../../src/pages/contribuer.tsx" /* webpackChunkName: "component---src-pages-contribuer-tsx" */),
  "component---src-pages-equipe-tsx": () => import("./../../../src/pages/equipe.tsx" /* webpackChunkName: "component---src-pages-equipe-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-tsx": () => import("./../../../src/pages/mentions-legales.tsx" /* webpackChunkName: "component---src-pages-mentions-legales-tsx" */),
  "component---src-pages-share-tsx": () => import("./../../../src/pages/share.tsx" /* webpackChunkName: "component---src-pages-share-tsx" */),
  "component---src-pages-shared-tsx": () => import("./../../../src/pages/shared.tsx" /* webpackChunkName: "component---src-pages-shared-tsx" */),
  "component---src-pages-workshop-tsx": () => import("./../../../src/pages/workshop.tsx" /* webpackChunkName: "component---src-pages-workshop-tsx" */),
  "component---src-templates-action-tsx": () => import("./../../../src/templates/action.tsx" /* webpackChunkName: "component---src-templates-action-tsx" */)
}

